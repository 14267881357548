import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';

import { environment } from '../../environments';
import { Notification, SocketNotificationsResponse } from '../../models';
import { Functions } from '../../utils';
import { SocketIOService } from '../socket-io/socket-io.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService implements OnDestroy {

  unreadQuestInstance: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  unreadTotal: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private httpClient: HttpClient,
              private socketIOService: SocketIOService) {

    this.socketIOService.notifications
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((response: SocketNotificationsResponse) => {
        this.unreadTotal.next(response.numberUnreadNotifications);
        this.unreadQuestInstance.next(response.numberUnreadNotificationsQuestInstance);
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getUnreadQuestInstance(): Observable<number> {
    return this.unreadQuestInstance.asObservable();
  }

  setUnreadQuestInstance(value: number): void {
    this.unreadQuestInstance.next(value);
  }

  getUnreadTotal(): Observable<number> {
    return this.unreadTotal.asObservable();
  }

  setUnreadTotal(value: number): void {
    this.unreadTotal.next(value);
  }

  getNotifications(queryParams: any = {}): Observable<{ items: Notification[], total: number }> {
    const params: HttpParams = Functions.objectQueryToHttpParams(queryParams);
    return this.httpClient.get<{
      items: Notification[],
      total: number
    }>(environment.apiURL + '/notifications', { params });
  }

  openNotifications(data: any = {}): Observable<any> {
    return this.httpClient.put<any[]>(environment.apiURL + '/notifications/open', data);
  }

  archiveNotifications(data: any = {}): Observable<any> {
    return this.httpClient.put<any[]>(environment.apiURL + '/notifications/archive', data);
  }

  deleteNotifications(data: any = {}): Observable<any> {
    return this.httpClient.delete<any[]>(environment.apiURL + '/notifications', { params: data });
  }

  openNotification(notificationId: string): Observable<any> {
    return this.httpClient.put<any[]>(environment.apiURL + '/notifications/' + notificationId + '/open', {});
  }

  archiveNotification(notificationId: string, data: { archive: boolean }): Observable<any> {
    return this.httpClient.put<any[]>(environment.apiURL + '/notifications/' + notificationId + '/archive', data);
  }

  deleteNotification(notificationId: string): Observable<any> {
    return this.httpClient.delete<any[]>(environment.apiURL + '/notifications/' + notificationId);
  }
}
