import { HttpInterceptorFn } from '@angular/common/http';

import { environment } from '../../environments';

export const versionInterceptor: HttpInterceptorFn = (req, next) => {
  const version: string = environment.version;

  return next(req.clone({
    setHeaders: {
      'app-version': version
    }
  }));
};
