import { HttpParams } from '@angular/common/http';

import * as moment from 'moment-timezone';

import { MediaFile, MessageInput, TimezoneData, User } from '../models';

export class Functions {

  static createArrayFromNumber(length: number): number[] {
    return Array.from({ length }, (_, i) => i + 1);
  }

  static removeUndefinedValuesFromObject = (obj: any): any => {
    Object.keys(obj).forEach((key) => ([undefined, null, ''].indexOf(obj[key]) !== -1) && delete obj[key]);
    return obj;
  };

  static sortObjectKeys(obj: any): any {
    if (obj === null || typeof obj !== 'object') {
      return obj;
    }

    if (Array.isArray(obj)) {
      return obj.map(Functions.sortObjectKeys);
    }

    const sortedKeys = Object.keys(obj).sort();
    const result: any = {};
    for (const key of sortedKeys) {
      result[key] = Functions.sortObjectKeys(obj[key]);
    }

    return result;
  }

  static getUnsignedAWSUrl(url: string): string {
    return url.replace(/https:\/\/(\S*?)\.s3\.\S*?\.amazonaws\.com\/([^\?]*)\S*?.*/g, 'https://$1.s3.amazonaws.com/$2');
  }

  static objectQueryToHttpParams(query: any = {}): HttpParams {
    return Object.entries(query)
      .reduce((params, [key, value]): HttpParams => {
        if (Array.isArray(value)) {
          value.forEach(v => {
            params = params.append(key + '[]', v);
          });
          return params;
        } else {
          return params.append(key, typeof value === 'string' ? value : JSON.stringify(value));
        }
      }, new HttpParams());
  }

  static cleanQueryParamsFilters(filters: any, allowedProperties: string[] = []): any {
    return Object.keys(filters).reduce((accumulator: any, key: string) => {
      if (allowedProperties.indexOf(key) !== -1) {
        if (filters[key].length > 0) {
          accumulator[key] = filters[key];
        }
      }
      return accumulator;
    }, {});
  }

  static fileTypeToMediaType(type: string): string {
    let mediaType: string = type.split('/')[0];
    if (type === 'image/heic' || !['image', 'audio', 'video'].includes(mediaType)) {
      mediaType = 'application';
    }

    return mediaType;
  }

  static blobResponseToMediaFile(response: any, fileName: string, link: string): MediaFile {
    return {
      file: response.body,
      fileName,
      urlFile: URL.createObjectURL(response.body),
      size: Number(response.headers.get('Content-Range')?.slice(10)),
      contentType: response.headers.get('Content-Type'),
      type: Functions.fileTypeToMediaType(response.headers.get('Content-Type')),
      link
    };
  }

  static prepareMessageInputData(messageInput: MessageInput): MessageInput {
    const data: any = {
      text: messageInput.text
    };

    if (messageInput.mediaLinks) {
      messageInput.mediaLinks = messageInput.mediaLinks
        .map(link => link.replace('.eu-central-1.amazonaws.com', '.amazonaws.com').split('?')[0]);
    }

    data.links = (messageInput.mediaLinks || []).concat(messageInput.links || []);
    return data;
  }

  static arrayToObjectByKey(array: any[], key: string): any {
    return array.reduce((a, b) => ({ ...a, [b[key]]: b }), {});
  }

  static getTimezonesNames(): TimezoneData[] {
    const arr: TimezoneData[] = [];
    const names = moment.tz.names();

    for (const name of names) {
      if ((name.indexOf('/') < 0 && name !== 'UTC') || name.startsWith('Etc/')) {
        continue;
      }
      const data: TimezoneData = {
        value: name,
        name: moment.tz(name).format('Z')
      };

      arr.push(data);
    }
    arr.sort((a, b) => {
      if (a.name === b.name) {
        if (a.value === 'UTC') {
          return -1;
        }
        return a.value === b.value ? 0 : (a.value > b.value ? 1 : -1);
      }
      const afc = a.name.charAt(0);
      const bfc = b.name.charAt(0);
      if (afc === '-') {
        if (bfc === '+') {
          return -1;
        }
        return a.name > b.name ? -1 : 1;
      }
      if (bfc === '-') {
        return 1;
      }
      return a.name > b.name ? 1 : -1;
    });

    arr.forEach(a => a.name = `(GMT${ a.name }) ${ (a.value).replace('_', ' ') }`);
    return arr;
  }

  static getHelpURL(user: User): string {
    let url = '';
    if (user.professional) {
      if (user.language === 'fr') {
        url = 'https://innership.notion.site/iNNERSHiP-COACH-931beafb3dbb458e90366ca690ac2eef';
      } else {
        url = 'https://innership.notion.site/iNNERSHiP-COACH-931beafb3dbb458e90366ca690ac2eef#87ea6aa7bf824e5d88873af505d63442';
      }
    } else {
      if (user.language === 'fr') {
        url = 'https://innership.notion.site/Trousse-outils-du-participant-661d4803a7244632905560152a6d89b9';
      } else {
        url = 'https://innership.notion.site/Participant-Toolkit-2cb7b59ca2a04f8ba20ce649d77759b9';
      }
    }
    return url;
  }
}
