import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { AlertController, ToastController } from '@ionic/angular';

import { addIcons } from 'ionicons';
import { alertCircleOutline } from 'ionicons/icons';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(private alertController: AlertController,
              private toastController: ToastController,
              private translateService: TranslateService) {
    addIcons({
      alertCircleOutline
    });
  }

  async presentForbiddenHttpRequestAlert() {
    const alert = await this.alertController.create({
      header: this.translateService.instant('ALERT_FORBIDDEN_HTTP_REQUEST_HEADER'),
      message: this.translateService.instant('ALERT_FORBIDDEN_HTTP_REQUEST_MESSAGE'),
      buttons: [this.translateService.instant('BTN_OK')]
    });

    await alert.present();
  }

  async presentToast(message: string, cssClass = 'warning-toast', duration = 1500) {
    const toast = await this.toastController.create({
      message,
      cssClass,
      duration,
      position: 'top'
    });

    await toast.present();
  }

  async presentPermissionToast(input = 'CAMERA') {
    const toast = await this.toastController.create({
      message: this.translateService.instant('PERMISSION_MESSAGE_' + input),
      cssClass: 'warning-toast',
      duration: 1500,
      position: 'top',
      icon: 'alert-circle-outline'
    });

    await toast.present();
  }
}
