import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router, UrlTree } from '@angular/router';
import { first, Observable, ReplaySubject, shareReplay, tap } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';

import * as moment from 'moment-timezone';

import { Platform } from '@ionic/angular';
import { LoadingController } from '@ionic/angular/standalone';

import { environment } from '../../environments';
import { AuthResult, AuthSubscriptionResult, User } from '../../models';
import {
  LocalStorageService,
  NotificationService,
  SignUpDataService,
  SocketIOService,
  UserService
} from '../../services';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  protected authenticatedUser: ReplaySubject<User> = new ReplaySubject<User>(1);

  constructor(private httpClient: HttpClient,
              private loadingController: LoadingController,
              private platform: Platform,
              private router: Router,
              private translateService: TranslateService,
              private localStorageService: LocalStorageService,
              private notificationService: NotificationService,
              private signUpDataService: SignUpDataService,
              private socketIOService: SocketIOService,
              private userService: UserService) {
  }

  getAuthenticatedUser(): Observable<User> {
    return this.authenticatedUser;
  }

  subscription(email: string): Observable<AuthSubscriptionResult> {
    const params: HttpParams = new HttpParams().set('email', email);
    return this.httpClient.get<AuthSubscriptionResult>(environment.apiURL + '/auth/subscription', { params });
  }

  login(email: string, password: string): Observable<AuthResult> {
    return this.httpClient.post<AuthResult>(environment.apiURL + '/auth/login', { email, password })
      .pipe(tap(result => this.setSession(result)), shareReplay());
  }

  forgotPassword(email: string): Observable<void> {
    return this.httpClient.post<void>(environment.apiURL + '/auth/password/forgot', { email });
  }

  resetPassword(code: string, data: { email: string, password: string }): Observable<any> {
    return this.httpClient.post<any>(environment.apiURL + '/auth/password/forgot/' + code, data);
  }

  async isLoggedIn(urlTree = false): Promise<boolean | UrlTree> {
    const token = await this.localStorageService.get('token');
    return token ? true : (urlTree ? this.router.parseUrl('/auth') : false);
  }

  setAuthenticatedUser(user: User): number {
    this.authenticatedUser.next(user);

    if (!user.confirmed) {
      this.signUpDataService.exists = true;
      this.signUpDataService.isSSO = !!user.singleSignOn;
      this.signUpDataService.email = user.email;
      this.signUpDataService.firstName = user.firstName;
      this.signUpDataService.lastName = user.lastName;
      this.signUpDataService.timezone = moment.tz.guess();

      if (!this.signUpDataService.submitting) {
        this.router.navigate(['/auth/recognized']);
      }
      return -1;
    }

    if (user.flaggedForDeletion) {
      this.router.navigate(['reactivate-account']);
      return -1;
    }

    this.socketIOService.connect();
    this.setLanguage(user.language);

    /*
    TODO: Implement notification service
    if (this.platform.is('capacitor') && user.communication.mobilePushNotification) {
      this.notificationService.setMobilePushNotifications();
      this.notificationService.initUnreadNotifications();
    } else if (this.platform.is('desktop') || this.platform.is('mobileweb')) {
      this.notificationService.setDesktopPushNotifications();
    }
     */

    this.getAndSetUnreadTotalNotifications();

    return 0;
  }

  getAndSetUnreadTotalNotifications(): void {
    this.notificationService
      .getNotifications({ limit: 1, filter: { opened: false } })
      .pipe(first())
      .subscribe(({ total }) => {
        this.notificationService.setUnreadTotal(total);
      });
  }

  setLanguage(lang: string): void {
    this.translateService.setDefaultLang(lang);
    this.translateService.use(lang);
  }

  private async setSession(authResult: AuthResult): Promise<void> {
    await this.localStorageService.set('token', authResult.token);
    this.setAuthenticatedUser(authResult.user);
  }

  async logout(): Promise<void> {
    const loading = await this.loadingController.create({
      message: this.translateService.instant('LOADING_LOGOUT_MESSAGE')
    });

    loading.present();

    // this.notificationService.removeDeviceRegistration().then(() => {
    this.userService.disconnect()
      .pipe(first())
      .subscribe(() => {
        this.disconnect();
        loading.dismiss();
      });
    // });
  }

  disconnect(): void {
    this.localStorageService.remove('token').then(() => {
      this.socketIOService.disconnect();
      this.router.navigate(['/']).then(() => {
        window.location.reload();
      });
    });
  }
}
