import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments';
import { S3SignedUrl } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class UploadS3Service {

  constructor(private httpClient: HttpClient) {
  }

  getSignedUrl(name: string, type: string): Observable<S3SignedUrl> {
    const params: HttpParams = new HttpParams()
      .set('fileName', name)
      .set('fileType', type);

    return this.httpClient.get<any>(environment.apiURL + '/s3/sign', { params });
  }

  uploadFile(signedUrl: string, file: File): Observable<any> {
    return this.httpClient.put<any>(signedUrl, file, {
      reportProgress: true,
      observe: 'events'
    });
  }
}
