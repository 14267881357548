import { deviceInterceptor } from './device/device.interceptor';
import { errorInterceptor } from './error/error.interceptor';
import { versionInterceptor } from './version/version.interceptor';
import { tokenInterceptor } from './token/token.interceptor';

export const httpInterceptorProviders = [
  deviceInterceptor,
  errorInterceptor,
  tokenInterceptor,
  versionInterceptor
];
