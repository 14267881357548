import { ResolveFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { first } from 'rxjs';

import { QuestInstanceService } from '../../services';

export const meetingInstanceResolver: ResolveFn<boolean> = (route, state) => {
  const router: Router = inject(Router);
  const questInstanceService: QuestInstanceService = inject(QuestInstanceService);
  const extrasState = router.getCurrentNavigation()?.extras.state;

  const meetingInstance = extrasState?.['meetingInstance'];
  const questInstanceId = route.parent?.parent?.paramMap.get('questInstanceId');
  const meetingInstanceId = route.paramMap.get('meetingInstanceId');

  return meetingInstance && meetingInstance.id === meetingInstanceId ? meetingInstance :
    (meetingInstanceId && questInstanceId ? questInstanceService.getQuestInstanceMeetingInstance(questInstanceId, meetingInstanceId).pipe(first()) : null);
};
