import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments';
import { User, UserAccess } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private httpClient: HttpClient) {
  }

  createUser(user: any): Observable<User> {
    return this.httpClient.post<User>(environment.apiURL + '/users', user);
  }

  getCurrentAccess(email: string): Observable<UserAccess> {
    const params: HttpParams = new HttpParams().set('email', email);
    return this.httpClient.get<UserAccess>(environment.apiURL + '/users/current-access', { params });
  }

  getCurrentUser(cognitoToken: string | null = null): Observable<User> {
    let headers = new HttpHeaders();
    if (cognitoToken) {
      headers = headers.append('cognito-access-token', cognitoToken);
    }

    return this.httpClient.get<User>(environment.apiURL + '/users/current', { headers });
  }

  updateCurrentUser(data: any): Observable<User> {
    return this.httpClient.put<User>(environment.apiURL + '/users/', data);
  }

  updateCurrentUserPassword(oldPassword: string, password: string): Observable<User> {
    return this.httpClient.put<User>(environment.apiURL + '/users/password', {
      oldPassword: oldPassword,
      password: password
    });
  }

  getUserAuthorizedLanguages(): Observable<string[]> {
    return this.httpClient.get<string[]>(environment.apiURL + '/users/authorized-languages');
  }

  disconnect(): Observable<void> {
    return this.httpClient.delete<void>(environment.apiURL + '/users/disconnect');
  }
}
