// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiURL: 'https://dev-v3-api.innership.com',
  socketIO: 'https://dev-v3-api.innership.com',
  locales: ['en', 'fr', 'pt-MZ'],
  firebase: {
    apiKey: 'AIzaSyDqYsGnF7NJi69EmyAEfpOI_Ge5ViASNYs',
    authDomain: 'innership-v3.firebaseapp.com',
    projectId: 'innership-v3',
    storageBucket: 'innership-v3.appspot.com',
    messagingSenderId: '786684853500',
    appId: '1:786684853500:web:51938e5b8a620cd0e82b79',
    measurementId: 'G-07XBP5C9NN'
  },
  firebaseVapidKey: 'BC-m9WO9szK45uAUXmGsF8Sh6IBJnxXIULUrK2uDvh7oWn2mRm3QNj2LppgQqfGBxLbSQZIq2fc82QlBtnXitHc',
  version: '1.5.5'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
