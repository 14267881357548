import { HttpInterceptorFn, HttpResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { from, switchMap, tap } from 'rxjs';

import { LocalStorageService } from '../../services';

export const tokenInterceptor: HttpInterceptorFn = (req, next) => {

  const localStorageService: LocalStorageService = inject(LocalStorageService);

  return from(localStorageService.get('token'))
    .pipe(
      switchMap(token => {
        const headersConfiguration = {
          Accept: 'application/json'
        };

        if (token) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          headersConfiguration[`innership-token`] = `${ token }`;
        }

        req = req.clone({ setHeaders: headersConfiguration });

        return next(req).pipe(tap((res: any) => {
          if (res instanceof HttpResponse) {
            if (res.headers.get('Refresh-Token')) {
              localStorageService.set('token', res.headers.get('Refresh-Token'));
            }
          }
        }, () => {

        }));
      }));


};
